body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, 'Oxygen', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

.fixed-body {
  background-color: white !important;
  overflow: hidden;
}

.MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 12px 0px !important;
}

html * {
  font-family: 'Nunito', Arial, Helvetica, sans-serif !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.app-container {
  background-color: white;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 10px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#boom-login-mask {
  min-height: 100px;
  min-width: 100%;
  text-align: center;
  height: 100vh;
}

.logo {
  height: 30px;
  margin-left: 15px;
}

.rbc-event-label {
  display: none !important;
}

.rbc-current-time-indicator {
  display: none !important;
}

.rbc-row-segment {
  padding-right: 20px !important;
  padding-top: 0px !important;
}

.rbc-event-content {
  background-color: white;
}
.rbc-toolbar {
  flex-direction: row !important;
}

@media (min-width: 320px) {
  h4 {
    /* smartphones, iPhone, portrait 480x320 phones */
    font-size: 15px;
  }
  h5 {
    font-size: 12px;
  }
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  h4 {
    font-size: 20px;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  h4 {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  #event-board {
    display: none;
  }
  .rbc-toolbar {
    flex-direction: column !important;
  }
}

@media (min-width: 960px) {
  .MuiTab-root-225 {
    min-width: 115px !important;
  }
}

.link-label {
  color: rgb(136, 189, 251);
}
.link-label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.rbc-agenda-date-cell {
  color: #3f3f3f;
}

.rbc-agenda-time-cell {
  color: #3f3f3f;
}
.rbc-day-bg.rbc-today {
  background-color: white;
}

.rbc-date-cell.rbc-now.rbc-current {
  background-color: #5ac0b1;
  color: white;
}

.lozio:hover {
  background-color: white;
}

.chartWrapper {
  position: relative;
  width: 100%;
  overflow-x: scroll;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  overflow-x: scroll;
}
