.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   *
   * If you're not using "display: flex", you can remove this style.
   */
  margin-top: 0px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #00b79b;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #00b79b;
  font-size: 24px;
  dominant-baseline: middle;
  text-anchor: middle;
}
